<template>
  <div id="set_view" class="popup">
    <div class="popup_box">
      <div class="popup_cont">
        <h1>원하시는 테이블 화면을 선택해주세요</h1>
        <table class="tbl5 w700">
          <tbody>
          <tr>
            <td>
              <div class="alignCenter m_b10">
                <strong>와이드</strong>
              </div>
              <div class="cc-selector">
                <input id="wide_tbl" type="radio" name="table_mode" v-model="roomTypeIndex" value="2" />
                <label class="drinkcard-cc" for="wide_tbl"><img
                    src="/resources/img/Wide_View.jpg"></label>
              </div>
            </td>
            <td>
              <div class="alignCenter m_b10">
                <strong>일반</strong>
              </div>
              <div class="cc-selector">
                <input id="normal_tbl" type="radio" name="table_mode" v-model="roomTypeIndex" value="1" />
                <label class="drinkcard-cc" for="normal_tbl"><img
                    src="/resources/img/Normal_View.jpg"></label>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_area">
        <button type="button" class="btn btn_sm btn_grd1" @click="$API.util.closePopup('set_view');">취소</button>
        <button type="button" class="btn btn_sm btn_grd1" @click="changeRoomType()">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-room-type-component',
  props: {
    companyKey: String,
    tableKey: String,
    minmaxIndex: Number,
    isLobby: Boolean
  },
  components: {
  },
  data () {
    return {
      roomTypeIndex: '1'
    }
  },
  methods: {
    changeRoomType () {
      localStorage.setItem('roomType', this.roomTypeIndex)
      this.$API.util.closePopup('set_view')

      if (!this.isLobby) {
        // 방을 이동한다.
        const token = this.$store.getters['auth/token']
        if (this.roomTypeIndex === '1') {
          document.location.href = `/game/${this.companyKey}/${this.tableKey}?token=${token}&minmax=${this.minmaxIndex}`
        } else {
          document.location.href = `/game/${this.companyKey}/${this.tableKey}/wide?token=${token}&minmax=${this.minmaxIndex}`
        }
      }
    },
    init () {
      let idx = localStorage.getItem('roomType')
      if (!idx) idx = '1'
      this.roomTypeIndex = idx
    }
  },
  mounted() {

  }
}
</script>