<template>
  <div class="lbox3_cont1">
    <canvas :id="'cvsSix-' + room._id" width="853" height="280" style="width: 100%; height: 100%;"></canvas>
  </div>
  <div class="lbox3_cont2">
    <canvas :id="'cvsOrg-' + room._id" width="853" height="280" style="width: 100%; height: 100%;"></canvas>
  </div>
  <div class="lbox3_cont3">
    <canvas :id="'cvsOne-' + room._id" width="853" height="280" style="width: 100%; height: 100%;"></canvas>
  </div>
</template>


<script>
import HistorySixDrawer from "@/libraries/HistorySixDrawer"
import HistoryOrgDrawer from "@/libraries/HistoryOrgDrawer"
import History123Drawer from "@/libraries/History123Drawer"

const RESULT_PLAYER = 0;
const RESULT_BANKER = 1;
const RESULT_TIE = 2;

export default {
  name: 'room-wide-history-panel-component',
  props: {
    currentRoom: Object
  },
  data: function () {
    return {
      cvsSix: null,
      cvsOrg: null,
      cvsOne: null,
      drawerSix: null,
      drawerOrg: null,
      drawerOne: null,
      drawerTwo: null,
      drawerThree: null,
      tmPreview: null,
      previewCount: 0,
      currentHistoryCardIndex: -1
    }
  },
  computed: {
    history: function () {
      return this.currentRoom == null ? [] : this.currentRoom.histories
    },
    room: function () {
      return this.currentRoom;
    }
  },
  methods: {
    isMobile: function () {
      return /.*Android.*|.*iPad.*|.*iPhone.*/i.test(window.navigator.userAgent);
    },
    redraw: function (history) {
      let ctxSix = this.cvsSix.getContext("2d");
      let ctxOrg = this.cvsOrg.getContext("2d");
      let ctxOne = this.cvsOne.getContext("2d");

      this.drawerSix.init(ctxSix, 0, 0, this.cvsSix.width, this.cvsSix.height, 18, 6);
      this.drawerSix.redraw(history, this.room != null && this.room.isDT, this.room == null || '');

      this.drawerOrg.init(ctxOrg, 0, 0, this.cvsOrg.width, this.cvsOrg.height, 18, 6);
      this.drawerOrg.cellPadding = 5;
      this.drawerOrg.redraw(history, this.room != null && this.room.isDT, this.room == null || '');

      this.drawerOne.init(ctxOne, 0, 0, this.cvsOne.width, this.cvsOne.height / 2, 36, 6, 1);
      this.drawerOne.redraw(this.drawerOrg.origins, this.room != null && this.room.isDT, this.room == null || '');

      this.drawerTwo.init(ctxOne, 0, this.cvsOne.height / 2, this.cvsOne.width / 2, this.cvsOne.height / 2, 18, 6, 2);
      this.drawerTwo.redraw(this.drawerOrg.origins, this.room != null && this.room.isDT, this.room == null || '');

      this.drawerThree.init(ctxOne, this.cvsOne.width / 2, this.cvsOne.height / 2, this.cvsOne.width / 2, this.cvsOne.height / 2, 18, 6, 3);
      this.drawerThree.redraw(this.drawerOrg.origins, this.room != null && this.room.isDT, this.room == null || '');

      ctxOne.lineWidth = 2.0;
      ctxOne.strokeStyle = "#000000";
      ctxOne.beginPath();
      ctxOne.moveTo(0, this.cvsOne.height / 2);
      ctxOne.lineTo(this.cvsOne.width, this.cvsOne.height / 2);
      ctxOne.stroke();

      ctxOne.beginPath();
      ctxOne.moveTo(this.cvsOne.width / 2, this.cvsOne.height / 2);
      ctxOne.lineTo(this.cvsOne.width / 2, this.cvsOne.height);
      ctxOne.stroke();
    },
    updatePreviewResults: function (pResults, bResults) {
      let cls = [".future-one", ".future-two", ".future-three"];
      let clsPlayer = "future-blue";
      let clsBanker = "future-red";

      for (let idx = 0; idx < 3; idx++) {
        let isslash = idx == 1 ? "-circle" : (idx == 2 ? "-slash" : "");

        // future-banker 처리
        let futureBankerElements = document.querySelectorAll(".future-banker " + cls[idx]);
        futureBankerElements.forEach(element => {
          element.className = "future future-none " + cls[idx].substr(1);
          if (bResults[idx] >= 0) {
            element.classList.remove("future-none");
            element.classList.add(bResults[idx] == 1 ? clsBanker + isslash : clsPlayer + isslash);
          }
        });

        // future-player 처리
        let futurePlayerElements = document.querySelectorAll(".future-player " + cls[idx]);
        futurePlayerElements.forEach(element => {
          element.className = "future future-none " + cls[idx].substr(1);
          if (pResults[idx] >= 0) {
            element.classList.remove("future-none");
            element.classList.add(pResults[idx] == 1 ? clsBanker + isslash : clsPlayer + isslash);
          }
        });
      }
    },
    updateHistory: function () {
      if (this.room == null) return;
      this.redraw(this.history);
      this.updatePreviewResults(this.getPreview123(RESULT_PLAYER), this.getPreview123(RESULT_BANKER));
    },
    getPreview123: function (iWinType) {
      let arrRet = [-1, -1, -1];
      for (let i = 1; i <= 3; i++) {
        if (this.drawerOrg.origins == null || this.drawerOrg.origins.length < 1) continue;
        let iCol = this.drawerOrg.origins.length - 1;
        let iRow = this.drawerOrg.origins[iCol].length - 1;
        if (iCol < i) continue;
        let lastResult = this.drawerOrg.origins[iCol][iRow];
        let baseLink = false;
        if (this.drawerOrg.origins[iCol - i] != void 0 && this.drawerOrg.origins[iCol - i][0].result == RESULT_TIE) continue;
        if (this.drawerOrg.origins[iCol - i] !== void 0 && this.drawerOrg.origins[iCol - i][iRow] !== void 0) {
          if (this.drawerOrg.origins[iCol - i][iRow + 1] !== void 0) baseLink = true;
        } else {
          baseLink = true;
        }
        let link = lastResult.result == iWinType;
        arrRet[i - 1] = baseLink == link ? RESULT_BANKER : RESULT_PLAYER;
      }
      return arrRet;
    },
    stopHistoryPreview: function () {
      if (this.tmPreview != null) {
        clearInterval(this.tmPreview);
        this.tmPreview = null;
        this.redraw(this.history);
      }
    },
    onHistoryUpdate: function (gameCode, roomId) {
      if (this.room == null || this.room.gamecode != gameCode || this.room.id != roomId) return;
      this.stopHistoryPreview();
      this.updateHistory();
    },
    renderPreviewHistory: function (vResult) {
      let t = this;
      this.stopHistoryPreview();
      this.previewCount = 0;
      if (vResult < 0) return;

      t.redraw(t.history.concat(vResult));
      this.tmPreview = setInterval(function () {
        if (t.previewCount % 2 != 0) {
          t.redraw(t.history.concat(vResult));
        } else {
          t.redraw(t.history);
        }
        t.previewCount++;
        if (t.previewCount >= 5) t.stopHistoryPreview();
      }, 500);
    },
    clearHistory: function () {
      this.stopHistoryPreview();
      this.updateHistory();
    },
    getHistoryCount () {
      return {
        b: this.drawerSix.bCount,
        p: this.drawerSix.pCount,
        t: this.drawerSix.tCount,
        pp: this.drawerSix.ppCount,
        bp: this.drawerSix.bpCount
      }
    }
  },
  created: function () {

  },
  unmounted: function () {

  },
  mounted: function () {
    this.cvsSix = document.getElementById('cvsSix-' + this.currentRoom._id)
    this.cvsOrg = document.getElementById('cvsOrg-' + this.currentRoom._id)
    this.cvsOne = document.getElementById('cvsOne-' + this.currentRoom._id)

    this.drawerSix = new HistorySixDrawer(this.$API);
    this.drawerOrg = new HistoryOrgDrawer(this.$API);
    this.drawerOne = new History123Drawer(this.$API);
    this.drawerTwo = new History123Drawer(this.$API);
    this.drawerThree = new History123Drawer(this.$API);
    this.updateHistory();
  }
}
</script>