<template>
  <div id="wide-mode" v-if="user && room && room.game">
    <div class="wide_container">
      <div class="video video-div">
        <video-player ref="videoPlayer" :tableKey="room.key" :companyKey="company.key" :server-url="room.data.vodMain" />
      </div>
    </div>
    <div class="wide-wrapper">
      <div class="wide_left">
        <div class="lbox1">
          <div class="ltitle1">{{ room == null ? '' : room.name }} <span id="ltitme_dgr">차수 : {{ room == null ? 0 : room.game.roundNum }}</span></div>
        </div>
        <room-wide-card-panel-component ref="room-card-panel" :room="room" v-show="showCardResult === true && !$API.util.isMobile()"></room-wide-card-panel-component>

        <div class="lbox3">
          <div :class="{lbox3_1:true, lbox3_dr:false}">
            <room-wide-history-panel-component :currentRoom="room" ref="room-history-panel-component" />
            <ul v-if="currentRoom">
              <li>
                <img src="/resources/img/icon-banker.png">
                <span class="b-txt">{{ currentRoom == null ? 0 : count.b }}</span>
              </li>
              <li>
                <img src="/resources/img/icon-player.png">
                <span class="p-txt">{{ currentRoom == null ? 0 : count.p }}</span>
              </li>
              <li>
                <img src="/resources/img/icon-tie.png">
                <span class="t-txt">{{ currentRoom == null ? 0 : count.t }}</span>
              </li>
              <li>
                <img src="/resources/img/icon-bp.png">
                <span class="b-txt">{{ currentRoom == null ? 0 : count.bp }}</span>
              </li>
              <li>
                <img src="/resources/img/icon-pp.png">
                <span class="p-txt">{{ currentRoom == null ? 0 : count.pp }}</span>
              </li>
            </ul>
            <div style="width: 40%; float: left">
              <div class="future-banker wide-future-box" @click="renderPreview('10')">
                <span class="blabel">B</span>
                <button type="button" class="future-one future-blue"></button>
                <button type="button" class="future-two future-blue-circle"></button>
                <button type="button" class="future-three future-blue-slash"></button>
              </div>
              <div class="future-player wide-future-box" @click="renderPreview('30')">
                <span class="plabel">P</span>
                <button type="button" class="future-one future-red"></button>
                <button type="button" class="future-two future-red-circle"></button>
                <button type="button" class="future-three future-red-slash"></button>
              </div>
            </div>
          </div>
          <div class="lbox3_2" @click="showMap();"></div>
        </div>
      </div>

      <div class="wide_right">
        <div class="rbox1">
          <div class="wbox_cont1">
            <ul>
              <li>보유금액 : </li>
              <li><span v-if="$store.getters['auth/user']">{{ $API.util.getFormattedMoney(user.cash) }}</span></li>
              <li>배팅금액 : </li>
              <li>{{ $API.util.getFormattedMoney(currentTotalBetAmount) }}</li>
            </ul>
          </div>
        </div>
        <div class="rbox2">
          <div class="rbox3 otherRoom" id="move-room-wrapper">
            <div class="slc_room">
              <div class="slc_room_title">
                방 이동
                <button type="button" class="btn_image" @click="closeRoomMove"><img src="/resources/img/close-btn1.png"></button>
              </div>

              <div :class="{game_list:true, nomulti:true}">
                <div class="blocks grid-1">
                  <lobby-component ref="move-room-wrapper" :companyKey="companyKey" :lobbyColType="0" :lobbyHistoryType="2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="basic_log game-log-container" v-show="enableGameLog">
          <ul>
            <li v-for="(item, index) in gameLogs" v-bind:key="`gamelog-${index}`">{{item}}</li>
          </ul>
        </div>



        <div class="rbox2_1" id="bet_limit_tbl" style="display: none;">
          <table class="wtbl" v-if="currentRoom">
            <tr>
              <th>배팅</th>
              <th>배팅한도</th>
              <th>배당</th>
            </tr>
            <tr>
              <td>PLAYER</td>
              <td class="ytxt">MIN {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pb[0])}} ~ MAX {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pb[1])}}</td>
              <td>1:1</td>
            </tr>
            <tr>
              <td>BANKER</td>
              <td class="ytxt">MIN {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pb[0])}} ~ MAX {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pb[1])}}</td>
              <td>1:0.95</td>
            </tr>
            <tr>
              <td>TIE</td>
              <td class="ytxt">MIN {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].t[0])}} ~ MAX {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].t[1])}}</td>
              <td>1:8</td>
            </tr>
            <tr>
              <td>P PAIR</td>
              <td class="ytxt">MIN {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pair[0])}} ~ MAX {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pair[1])}}</td>
              <td>1:11</td>
            </tr>
            <tr>
              <td>B PAIR</td>
              <td class="ytxt">MIN {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pair[0])}} ~ MAX {{ currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pair[1])}}</td>
              <td>1:11</td>
            </tr>
          </table>
        </div>





      </div>

      <div class="wide_bottom">
        <div class="wboxs">
          <div class="wbox1">
            <div class="btn_box">
              <button type="button" class="btn btn_sm btn_grd2" @click="$API.util.toggleFullScreen"><img src="/resources/img/icon_001.png"/>전체화면</button>
              <button type="button" class="btn btn_sm btn_grd2" @click="setTableMode(true)"><img src="/resources/img/icon_010.png"/>화면모드</button>
              <button type="button" class="btn btn_sm btn_grd2" id="config_btn" @click='$API.util.openConfig'><img src="/resources/img/icon_004.png"/>설정</button>
              <button type="button" class="btn btn_sm btn_grd2" @click="restartVideo"><img src="/resources/img/icon_002.png"/>새로고침</button>
              <button type="button" class="btn btn_sm btn_grd2" @click="videoZoom">
                <img src="/resources/img/icon_009.png" />
                <span>확대보기</span>
              </button>
              <button type="button" class="btn btn_sm btn_grd2" @click="soundOnOff"><img src="/resources/img/icon_014.png" v-if="enableSound"/><img v-else src="/resources/img/icon_014-off.png"/>음소거</button>

              <ul class="config-menu" id="config-menu" style="left: 202px; top: -220px;">
                <!--<li class="menu-title">계정</li>
                <li onclick="showPop('set_nickname');">닉네임 설정</li>
                <li onclick="showPop('set_pwd');">비밀번호 변경</li>
                <div class="line"></div>-->
                <li class="menu-title">게임</li>
                <!-- <li @click="$root.onViewChipSetting">칩 유형 선택</li> //-->
                <li @click="setTableMode(false)">테이블모드 설정</li>
                <div class="line"></div>
                <li class="menu-title">환경</li>
                <!-- <li @click="$root.onViewNumberBetSetting">배팅설정</li> //-->
                <li @click="openSoundConfig">음향설정</li>
              </ul>

            </div>
          </div>
          <div class="wbox2">
            <button type="button" class="btn btn_sm btn_grd2" @click="openBets"><img src="/resources/img/icon_006.png"/>배팅기록</button>
            <button type="button" class="btn btn_sm btn_grd2" @click="onLimitClick"><img src="/resources/img/icon_006.png"/>배팅한도</button>
            <button type="button" class="btn btn_sm btn_grd2" @click="gameLogOnOff"><img src="/resources/img/icon_005.png"/>로그보기</button>
            <button type="button" class="btn btn_sm btn_grd2" @click="openRoomMove"><img src="/resources/img/icon_003.png"/>방이동</button>
            <button type="button" class="btn btn_sm btn_grd2" @click="goLobby"><img src="/resources/img/icon_003.png"/>로비이동</button>
          </div>
        </div>

        <div class="wbox_center">

          <room-chip-panel-component :chips="minmax.chips" :chipIndex="selectedChipIndex" @changeChipIndex="changeChipIndex" />
          <div class="wbox2_2">
            <button type="button" @click="tryBet" class="btn bet_btn bet_btn2"><img src="/resources/img/icon-check.png"/>배팅완료</button>
            <button type="button" @click="retryAddBet" class="btn bet_btn"><img src="/resources/img/icon-refresh2.png"/>다시배팅</button>
            <button type="button" @click="cancelBet" class="btn bet_btn"><img src="/resources/img/icon-x.png"/>배팅취소</button>
          </div>
        </div>

        <div class="wbox_center2">
          <div class="gameDisableWide" v-show="room.game.state !== 'betting'"></div>
          <radial-progress-bar-component ref="radial-progress" v-if="room && room.game && (room.game.state === 'betting' || room.game.state === 'nomorebet')" />
          <div class="m_cont5">
            <div class="m_bet_box ui_pp wppair" @click="addBet('pp')">
              <div class="box_title">P.페어</div>
              <div class="box_cont1">P.P / 1:11</div>
              <div :class="{box_cont2: true, betted: (bet.pp + totalBet.pp) > 0}" id="pp_price">{{$API.util.getFormattedMoney(bet.pp + totalBet.pp)}}</div>
            </div>
            <div class="m_bet_box_line"></div>
            <div class="m_bet_box ui_player wplayer" @click="addBet('p')">
              <div class="box_title">플레이어</div>
              <div class="box_cont1">Player / 1:1</div>
              <div :class="{box_cont2: true, betted: (bet.p + totalBet.p) > 0}" id="p_price">{{$API.util.getFormattedMoney(bet.p + totalBet.p)}}</div>
            </div>
            <div class="m_bet_box_line"></div>
            <div class="m_bet_box ui_tie wtie" @click="addBet('t')">
              <div class="box_title">타이</div>
              <div class="box_cont1">Tie / 1:8</div>
              <div :class="{box_cont2: true, betted: (bet.t + totalBet.t) > 0}" id="tie_price">{{$API.util.getFormattedMoney(bet.t + totalBet.t)}}</div>
            </div>
            <div class="m_bet_box_line"></div>
            <div class="m_bet_box ui_banker wbanker" @click="addBet('b')">
              <div class="box_title">뱅커</div>
              <div class="box_cont1">Banker / 1:0.95</div>
              <div :class="{box_cont2: true, betted: (bet.b + totalBet.b) > 0}" id="b_price">{{$API.util.getFormattedMoney(bet.b + totalBet.b)}}</div>
            </div>
            <div class="m_bet_box_line"></div>
            <div class="m_bet_box ui_bp wbpair" @click="addBet('bp')">
              <div class="box_title">B.페어</div>
              <div class="box_cont1">B.P / 1:11</div>
              <div :class="{box_cont2: true, betted: (bet.bp + totalBet.bp) > 0}" id="bp_price">{{$API.util.getFormattedMoney(bet.bp + totalBet.bp)}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div id="pop_message" class="popup_message" style="display: none" v-show="alert.visible">
    <div :class="{popup_black: alert.type == 1, popup_red: alert.type == 2, popup_blue: alert.type == 3}">
      <div class="popup_cont">
        <h1>{{ alert.msg }}</h1>
      </div>
      <div class="btn_area">
        <button type="button" class="btn btn_sm btn_grd1" @click="hideAlert()">닫기</button>
      </div>
    </div>
  </div>
  <bet-history-component :company="company" :table="room" @closeBets="closeBets" id="bet-history" ref="bet-history" />
  <select-room-type-component ref="config_room_type" :company-key="companyKey" :table-key="tableKey" :minmax-index="minmaxIndex" :is-lobby="false" />

  <div id="sound_config" class="popup">
    <div class="popup_box">
      <div class="popup_cont">
        <h1>음향설정</h1>
        <table class="tbl4 w450">
          <tbody>
          <tr>
            <th>로비배경음악</th>
            <td>
              <label class="switch-button"> <input type="checkbox" v-model="sound.bg" /> <span
                  class="onoff-switch"></span> </label>
            </td>
          </tr>
          <tr>
            <th>게임사운드</th>
            <td>
              <label class="switch-button"> <input type="checkbox" v-model="sound.effect" /> <span
                  class="onoff-switch"></span> </label>
            </td>
          </tr>
          <!-- <tr>
              <th>생생한 목소리</th>
              <td>
                  <label class="switch-button"> <input type="checkbox" id="chkSndConfigLiveVoice" /> <span
                          class="onoff-switch"></span> </label>
                  <p>라이브 테이블에 들어가면 라이브 음성이 일시적으로 음소거 됩니다.</p>
              </td>
          </tr> -->
          </tbody>
        </table>
      </div>
      <div class="btn_area">
        <button type="button" class="btn btn_sm btn_grd1" @click="closeSoundConfig">취소</button>
        <button type="button" class="btn btn_sm btn_grd1" @click="changeSoundConfig">확인</button>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
import RadialProgressBarComponent from "@/components/radial-progress-bar-component.vue"
import RoomWideCardPanelComponent from "@/components/room/room-wide-card-panel-component.vue"
import RoomChipPanelComponent from "@/components/room/room-chip-panel-component.vue"
import RoomWideHistoryPanelComponent from "@/components/room/room-wide-history-panel-component.vue"
import BetHistoryComponent from "@/components/room/bet-history-component.vue"
import { STATE } from "@/helpers/constant.helper"
import LobbyComponent from "@/components/lobby/lobby-component.vue";
import SelectRoomTypeComponent from "@/components/select-room-type-component.vue"
import VideoPlayer from "@/components/game/video-player.vue";

export default {
  name: 'room-normal-component',
  props: {

  },
  components: {
    VideoPlayer,
    SelectRoomTypeComponent,
    LobbyComponent,
    RoomWideHistoryPanelComponent,
    RoomChipPanelComponent,
    RoomWideCardPanelComponent,
    RadialProgressBarComponent,
    BetHistoryComponent
  },
  data() {
    return {
      alert: {
        type: 1,
        msg: '',
        visible: false
      },
      user: null,
      room: null,
      company: null,
      companyKey: '',
      firstRequest: true,
      tableKey: '',
      gameLogs: [],
      enableGameLog: false,
      enableSound: true,
      shownResults: [],
      sound: {
        bg: true,
        effect: true
      },
      count: {
        p: 0,
        b: 0,
        t: 0,
        pp: 0,
        bp: 0
      },
      cards: {                                  // 카드
        player: ['', '', ''],
        banker: ['', '', '']
      },
      videoPlayer: null,
      minmax: null,
      minmaxIndex: 0,
      selectedChipIndex: 1,
      showCardResult: false,                    // 카드 결과 값을 보여줄지 여부
      winAmount: 0,
      totalBet: {                               // 전체 배팅 금액
        p: 0,
        t: 0,
        b: 0,
        pp: 0,
        bp: 0
      },
      bet: {                                    // 완료되지 않은 배팅 금액
        p: 0,
        t: 0,
        b: 0,
        pp: 0,
        bp: 0
      },
      lastBet: {                                // 마지막 배팅 내역
        p: 0,
        t: 0,
        b: 0,
        pp: 0,
        bp: 0
      },
      table: null,
      STATE,
      requestInterval: 1000,
      tmPreview: null,
      showBottomArea: false
    }
  },
  computed: {
    currentGame: function() {
      return this.room.game
    },
    currentRoom: function () {
      return this.room
    },
    currentBetAmount () {
      let amount = 0
      for(const key in this.bet) {
        amount += this.bet[key]
      }

      return amount
    },
    currentTotalBetAmount () {
      let amount = 0
      for(const key in this.totalBet) {
        amount += this.totalBet[key]
      }

      return amount
    }
  },
  methods: {
    openSoundConfig: function() {
      // 음악 체크박스 설정
      const chkSndConfigMusic = document.getElementById('chkSndConfigMusic');
      if (chkSndConfigMusic) {
        chkSndConfigMusic.checked = this.$root.enableMusic;
      }

      // 사운드 체크박스 설정
      const chkSndConfigSound = document.getElementById('chkSndConfigSound');
      if (chkSndConfigSound) {
        chkSndConfigSound.checked = this.$root.enableSound;
      }
      this.$API.util.showPopup('sound_config');
      this.$API.util.openConfig()
    },
    closeSoundConfig () {
      this.$API.util.playSoundClick()
      this.$API.util.closePopup('sound_config')
    },
    changeSoundConfig () {
      localStorage.setItem('soundBG', this.sound.bg)
      localStorage.setItem('soundEffect', this.sound.effect)
      this.$API.util.playSoundClick()
      this.$API.util.closePopup('sound_config')

      if (this.sound.bg === true) {
        this.$API.util.playBG()
      }
    },
    setTableMode (a) {
      this.$API.util.playSoundClick()

      // 팝업 표시
      this.$API.util.showPopup('set_view');
      this.$refs.config_room_type.init()
      if (!a) this.$API.util.openConfig()
    },
    closeRoomMove () {
      this.$API.util.playSoundClick()

      const obj = document.getElementById('move-room-wrapper')
      if (obj) {
        if (this.$refs['move-room-wrapper']) {
          this.$refs['move-room-wrapper'].close()
        }

        obj.style.display = 'none'
      }
    },
    openRoomMove () {
      this.$API.util.playSoundClick()

      const obj = document.getElementById('move-room-wrapper')
      if (obj) {
        if (this.$refs['move-room-wrapper']) {
          this.$refs['move-room-wrapper'].open()
        }
        obj.style.display = 'block'
      }
    },
    closeBets () {
      this.$API.util.playSoundClick()

      const obj = document.getElementById('bet-history')
      if (obj) {
        obj.style.display = 'none'
      }
    },
    onLimitClick () {
      this.$API.util.playSoundClick()

      const betLimitTbl = document.getElementById('bet_limit_tbl')
      if (betLimitTbl) {
        if (betLimitTbl.style.display === 'none') {
          betLimitTbl.style.display = 'block'
          this.enableGameLog = false
        } else {
          betLimitTbl.style.display = 'none'
        }
      }
    },
    showMap () {
      this.$API.util.playSoundClick()

      const lbox3 = document.querySelector('.lbox3');

      if (lbox3) {
        const rect = lbox3.getBoundingClientRect();

        if (rect.left < 0) {
          lbox3.style.left = '0';
          lbox3.style.marginLeft = '15px';
          lbox3.style.background = "url('/resources/img/map_off.png') left top / contain no-repeat";
        } else {
          lbox3.style.left = '-425px';
          lbox3.style.marginLeft = '0';
          lbox3.style.background = "url('/resources/img/map_on.png') left top / contain no-repeat";
        }
      }
    },
    openBets () {
      this.$API.util.playSoundClick()

      const obj = document.getElementById('bet-history')
      if (obj) {
        obj.style.display = 'block'
        this.$refs['bet-history'].open()
      }
    },
    toggleBottomArea: function (e) {
      // this.showBottomArea = !this.showBottomArea
      if (document.querySelector('.bottom_area').style.display !== 'none') {
        e.target.parentElement.querySelector('img').style.transform = 'rotate(180deg)';
        document.querySelector('.table_area').style.width = '20%';
        document.querySelector('.bottom_area').style.display = 'none';
        document.querySelector('.table_area .table_box1').style.width = 'calc(50% - 40px)';
        document.querySelector('.table_area .table_box3').style.width = '50%';
      } else {
        e.target.parentElement.querySelector('img').style.transform = 'rotate(0deg)';
        document.querySelector('.table_area').style.width = '40%';
        document.querySelector('.table_area .table_box1').style.width = 'calc(25% - 40px)';
        document.querySelector('.table_area .table_box3').style.width = '75%';
        document.querySelector('.bottom_area').style.display = 'block';
      }
    },
    showInfo: function (msg) {
      this.showAlert(1, msg)
    },
    showResult: function (msg) {
      this.showAlert(3, msg)
    },
    showError: function (msg) {
      this.showAlert(2, msg);
    },
    showAlert: function (type, msg) {
      this.alert.type = type;
      this.alert.msg = msg;
      this.alert.visible = true;
      setTimeout(this.hideAlert, 2000);
    },
    hideAlert: function () {
      this.alert.visible = false;
    },
    adjustZoom () {
      var minWidth = 1800;
      var body = document.getElementsByTagName('body')[0];
      var chkMulti = 0;// document.getElementsByClassName("multi-wrapper").length;

      if (window.innerWidth < minWidth) {
        if( window.innerWidth < 768 && chkMulti < 1)  {
          if(window.innerWidth < 320 ) {
            body.style.zoom = 0.6;
          } else if(window.innerWidth < 400 ) {
            body.style.zoom = 0.7;
          } else if(window.innerWidth < 480 ) {
            body.style.zoom = 0.8;
          } else if(window.innerWidth < 768) {
            body.style.zoom = 0.9;
          } else {
            body.style.zoom = 1;
          }
        } else {
          //console.log((window.innerWidth / minWidth));
          body.style.zoom = (window.innerWidth / minWidth);
        }
      } else {
        body.style.zoom = 1;
      }
    },

    toggleLimit: function() {
      if(!this.$API.util.isMobile()) return;
      for(const key in this.refs['game-limit']) {
        const obj = this.refs['game-limit'][key]
        const displayStyle = obj.value.style.display
        obj.value.style.display = displayStyle === 'none' ? '' : 'none'
      }
      // $(this.$el).find("ul.game-limit").toggle();
    },
    renderPreview(res) {
      if (this.$refs['room-history-panel-component']) this.$refs['room-history-panel-component'].renderPreviewHistory(res)
    },
    videoZoom: function() {
      this.$API.util.playSoundClick()
    },
    soundOnOff: function () {
      this.$API.util.playSoundClick()

      this.enableSound = !this.enableSound
      this.videoPlayer.enableAudio(this.enableSound)
    },
    gameLogOnOff: function () {
      this.$API.util.playSoundClick()

      this.enableGameLog = !this.enableGameLog;
      if (this.enableGameLog) {
        document.querySelectorAll('.game-log-container').forEach(function(el) {
          el.style.display = 'block'
        })
      } else {
        document.querySelectorAll('.game-log-container').forEach(function(el) {
          el.style.display = 'none'
        })
      }
    },
    setCardResult (cards) {
      // {\"playerCards\":[{\"shape\":\"h\",\"number\":5},{\"shape\":\"d\",\"number\":1}],\"bankerCards\":[{\"shape\":\"s\",\"number\":1},{\"shape\":\"s\",\"number\":6}]}

      this.cards.player = ['', '', '']
      this.cards.banker = ['', '', '']

      let playerCards = this.cards.player
      let bankerCards = this.cards.banker

      for(const key in cards.playerCards) {
        let card = cards.playerCards[key]
        playerCards[key] = card.shape + card.number
      }

      for(const key in cards.bankerCards) {
        let card = cards.bankerCards[key]
        bankerCards[key] = card.shape + card.number
      }
    },
    // 방에서 결과 나올때 호출
    renderRoundResult: function (cards, roundResult) {
      this.noBetCount++;

      /*
      if (this.noBetCount > 5) {
        // _app.showInfo(CasinoConfig.NoBetLimit + "판동안 베팅이 없으셔서 로비로 이동됩니다.");
        setTimeout(this.goLobby, 2000);
      }
       */

      this.setCardResult(cards)

      const playerNumber = roundResult.playerNumber
      const bankerNumber = roundResult.bankerNumber

      this.showCardResult = true

      const result = {
        p: false,
        t: false,
        b: false,
        pp: false,
        bp: false
      }

      if (roundResult.result === '10') {
        result.b = true
      } else if (roundResult.result === '11') {
        result.b = true
        result.bp = true
      } else if (roundResult.result === '12') {
        result.b = true
        result.pp = true
      } else if (roundResult.result === '13') {
        result.b = true
        result.pp = true
        result.bp = true
      } else if (roundResult.result === '20') {
        result.t = true
      } else if (roundResult.result === '21') {
        result.t = true
        result.bp = true
      } else if (roundResult.result === '22') {
        result.t = true
        result.pp = true
      } else if (roundResult.result === '23') {
        result.t = true
        result.pp = true
        result.bp = true
      } else if (roundResult.result === '30') {
        result.p = true
      } else if (roundResult.result === '31') {
        result.p = true
        result.bp = true
      } else if (roundResult.result === '32') {
        result.p = true
        result.pp = true
      } else if (roundResult.result === '33') {
        result.p = true
        result.bp = true
        result.pp = true
      }

      if(result.p === true) {
        this.$API.util.playSoundPlayer()
      } else if (result.b === true) {
        this.$API.util.playSoundBanker()
      } else if (result.t === true) {
        this.$API.util.playSoundTie()
      }

      if (this.$API.util.isMobile() === true) {
        if (this.$refs['room-card-panel']) {
          this.$refs['room-card-panel-mobile'].setRoundResult({
            result,
            cards: this.cards,
            playerNumber,
            bankerNumber
          })
        }
      } else {
        if (this.$refs['room-card-panel']) {
          this.$refs['room-card-panel'].setRoundResult({
            result,
            cards: this.cards,
            playerNumber,
            bankerNumber
          })
        }
      }
    },

    goLobby () {
      document.location.href=`/${this.companyKey}?token=${this.$store.getters['auth/token']}`
    },

    initRoomState: function () {
      for (let i = 0; i < 6; i++) {
        let chipElement = document.querySelector("#betChips td.ui_chip" + (i + 1));
        if (chipElement) {
          chipElement.innerHTML = this.getChipMoneyUnit(this.chips[i]);
        }
      }

      this.noBetCount = 0;

      this.currentRoom.historyCards = [];

      let quickNoticePre = document.querySelector(".quick-notice-pre");
      let noticeWidth = (quickNoticePre ? quickNoticePre.offsetWidth : 0) + window.innerWidth + 100;
      let quickNoticeDiv = document.querySelector(".quick-notice div div");
      if (quickNoticeDiv) {
        quickNoticeDiv.style.width = noticeWidth + 'px';
        quickNoticeDiv.style.animationDuration = (noticeWidth / 50) + 's';
      }
    },

    //--------------------------------
    async myInfo () {
      try {
        const result = await this.$API.game.myInfo(this.room && this.room.game ? this.room.game.roundId : '')
        if (result.code !== 'OK') {
          throw result
        }

        // 이전 배팅 금액 확인
        for(const key in result.bets) {
          const bet = result.bets[key]
          this.totalBet[bet.target] += bet.amount
        }

        this.user = result.user
        this.$store.commit('auth/SET_CURRENT_USER', result.user)

        return true
      } catch(e) {
        this.$log.error(e)
        return false
      }
    },
    async getTable () {
      const self = this
      try {
        const result = await this.$API.game.table(this.companyKey, this.tableKey)
        if (result.code !== 'OK') {
          alert(result.message)
          return
        }

        this.room = result.table
        this.minmax = this.room.minmax[this.minmaxIndex]
        if (this.room.game) this.room.game = JSON.parse(this.room.game)
        this.company = result.company

        setTimeout(() => { self.getStatus().then() }, 1000)

        return true
      } catch(e) {
        console.error(e)
        return false
      }
    },
    cancelBet () {
      this.$API.util.playSoundClick()

      for(const key in this.bet) {
        this.bet[key] = 0
      }
    },
    async tryBet () {
      this.$API.util.playSoundClick()

      if (this.currentBetAmount <= 0) {
        // this.$refs['modal_error'].open({ title: '배팅 오류', content: '배팅할 영역을 선택하여 주세요.' })
        alert('배팅할 영역을 선택하여 주세요.')
        return
      }

      if (!this.room || !this.room.game || !this.room.game.roundId) {
        // this.$refs['modal_error'].open({ title: '배팅 오류', content: '테이블 또는 라운드 정보가 존재하지 않습니다.' })
        alert('테이블 또는 라운드 정보가 존재하지 않습니다.')
        return
      }

      const loader = this.$loading.show({ container: this.$refs.betPanel })
      try {
        const result = await this.$API.game.bet(this.company.key, this.room.key, this.room.game.roundId, this.minmaxIndex, this.bet)
        if (result.code !== 'OK') {
          // this.$refs['modal_error'].open({ title: result.code, content: result.message })
          alert(result.message)
          return
        }

        for(const key in this.bet) {
          this.totalBet[key] += this.bet[key]
          this.bet[key] = 0
        }

        this.$store.commit('auth/SET_CASH', result.balance)

        this.$API.util.playSoundBet()

      } catch(e) {
        // this.$refs['modal_error'].open({ title: 'EXCEPTION', content: e.message })
        alert(e.message)
      } finally {
        loader.hide()
      }
    },
    /**
     * 배팅을 하였다면 결과를 확인한다.
     * @returns {Promise<void>}
     */
    async checkBetResult (totalBetAmount, round) {
      const self = this
      let checkResult = true

      if (!this.room || !this.room.game || !this.room.game.roundId) {
        // this.$refs['modal_error'].open({ title: '배팅 오류', content: '테이블 또는 라운드 정보가 존재하지 않습니다.' })
        alert('테이블 또는 라운드 정보가 존재하지 않습니다.')
        return
      }

      try {
        const result = await this.$API.game.result(this.company.key, this.room.key, round)
        if (result.code !== 'OK') {
          if (result.code !== 'NOT_COMPLETE') {
            // this.$refs['modal_error'].open({title: result.code, content: result.message})
            alert(result.message)
          }
          return
        }

        checkResult = false

        let totalAmount = 0
        for(const key in result.bets) {
          const bet = result.bets[key]
          const amount = bet.payout
          if (amount > 0) {
            totalAmount += amount
          }
        }

        this.winAmount = totalAmount

        if (totalAmount > 0) {
          this.showAlert(totalAmount >= 0 ? 3 : 2, "당첨금액 : " + (totalAmount === 0 ? "" : totalAmount > 0 ? "+" : "") + this.$API.util.getFormattedMoney(totalAmount))
        }

        this.$store.commit('auth/SET_CASH', result.balance)

      } catch(e) {
        // this.$refs['modal_error'].open({ title: 'EXCEPTION', content: e.message })
        alert(e.message)
      } finally {
        if (checkResult === true) {
          setTimeout(() => {
            self.checkBetResult(totalBetAmount, round).then()
          }, 2000)
        }
      }

    },
    retryAddBet () {
      this.$API.util.playSoundClick()

      for(const key in this.lastBet) {
        this.bet[key] = this.lastBet[key]
      }
    },
    changeChipIndex (idx) {
      this.$API.util.playSoundClick()

      this.selectedChipIndex = idx
    },
    addBet (target) {
      this.$API.util.playSoundClick()

      const amount = this.minmax.chips[this.selectedChipIndex]
      this.bet[target] += amount
    },
    async getStatus () {
      const self = this
      try {
        const tables = [{ key: this.room.key, beginPosition: this.room.game ? this.room.game.historyLength : 0 }]

        const result = await this.$API.game.status(this.companyKey, tables)
        if (result.code !== 'OK') {
          // this.$refs['modal_error'].open({ title: result.code, content: result.message })
          alert(result.message)
          return
        }

        const table = result.tables[this.room.key]
        if (!table) {
          this.$log.error(this.room.key + ' 테이블 데이터가 존재하지 않습니다.')
          return
        }
        table.game = JSON.parse(table.game)

        // 슈체인지 이후 배팅 상태가 되면 출목을 초기화 한다.
        if (this.room && this.room.game) {
          if (this.room.game.state === this.STATE.SHOE_CHANGE && table.game.state === this.STATE.BETTING) {
            this.room.histories = []
            if (this.$refs['room-history-panel-component']) {
              this.$refs['room-history-panel-component'].updateHistory()
              this.count = this.$refs['room-history-panel-component'].getHistoryCount()
            }

          }
        }

        // 출목 추가
        for(const idx in table.history) {
          const h = table.history[idx]
          this.room.histories.push(h)
          if (this.$refs['room-history-panel-component']) {
            this.$refs['room-history-panel-component'].updateHistory()
          }
        }

        if (this.$refs['room-history-panel-component']) {
          this.count = this.$refs['room-history-panel-component'].getHistoryCount()
        }

        if (table.game.state === this.STATE.BETTING) {
          this.requestInterval = (table.game.second - 5) * 1000
          if (this.requestInterval <= 0) {
            this.requestInterval = table.game.second * 1000
          }
        }

        if (table.game.state === this.STATE.NO_MORE_BET) {
          this.requestInterval = table.game.second * 1000
        }

        if (table.game.state === this.STATE.STOP) {
          this.requestInterval = 2000
        }

        if (table.game.state === this.STATE.CLOSE) {
          this.requestInterval = 1000
        }

        if (table.game.state === this.STATE.SHOE_CHANGE) {
          this.requestInterval = 5000
        }

        if (table.game.state === this.STATE.BETTING && this.firstRequest === true) {
          this.room.game.state = STATE.BETTING
          setTimeout(() => {
            self.$refs['radial-progress'].initTimerState()
            self.$refs['radial-progress'].onStartBet(self.room.game.second)
          }, 100)
        }

        // 배팅
        if (table.game.state === this.STATE.BETTING && this.room.game.state !== this.STATE.BETTING) {

          this.$log.debug('배팅시작')

          this.$API.util.playSoundBetStart()

          setTimeout(() => {
            self.showCardResult = false
            self.initBet()

          }, 2000)

          /*
          // 배팅 시작
          for(const key in this.totalBet) {
            this.totalBet[key] = 0
          }

          for(const key in this.bet) {
            this.bet[key] = 0
          }
           */

          this.room.game.state = STATE.BETTING
          setTimeout(() => {
            self.$refs['radial-progress'].initTimerState()
            self.$refs['radial-progress'].onStartBet(self.room.game.second)
          }, 100)

          // 배팅 중지
        } else if (table.game.state === this.STATE.NO_MORE_BET && this.room.game.state !== this.STATE.NO_MORE_BET) {
          this.$log.debug('배팅중지')
          this.$API.util.playSoundBetEnd()
          // 카드 오픈
        }else if (table.game.state === this.STATE.STOP && this.room.game.state !== this.STATE.STOP) {
          this.$log.debug('카드오픈')
          // 결과
        } else if (table.game.state === this.STATE.CLOSE && this.room.game.state !== this.STATE.CLOSE) {
          this.$log.debug('결과확인')

          // 1초 뒤에 결과값 확인
          const ri = table.game.roundId
          const totalBetAmount = this.currentTotalBetAmount
          if (this.currentTotalBetAmount > 0) {
            setTimeout(() => {
              self.checkBetResult(totalBetAmount, ri).then()
            }, 1000)
          }

          // 결과 출력(카드)
          if (table.game.result.result) {
            this.renderRoundResult(table.game.cards, table.game.result)

            setTimeout(() => {
              self.showCardResult = false
            }, 5000)
          }

          // 슈박스 체인지
        } else if (table.game.state === this.STATE.SHOE_CHANGE && this.room.game.state !== this.STATE.SHOE_CHANGE) {
          this.$log.debug('슈체인지')
        }

        this.room.game = table.game

      } catch(e) {
        this.$log.error(e)
      } finally {
        this.firstRequest = false
        setTimeout(() => {
          self.getStatus().then()
        }, self.requestInterval)
      }
    },
    startVideo () {
      this.$refs.videoPlayer.start()
    },
    async restartVideo () {
      this.$refs.videoPlayer.restart()
    },
    initBet () {
      for(const key in this.totalBet) {
        this.lastBet[key] = this.totalBet[key]
        this.totalBet[key] = 0
      }

      for(const key in this.bet) {
        this.bet[key] = 0
      }

      this.winAmount = 0
    },
    async init () {
      this.companyKey = this.$route.params.companyKey
      this.tableKey = this.$route.params.tableKey

      // this.createVideo()

      if (! this.companyKey) {
        alert('회사 코드를 확인하세요.')
        history.go(-1)
        return
      }

      if (! this.tableKey) {
        alert('테이블 코드를 확인하세요.')
        history.go(-1)
        return
      }

      await this.getTable()
      await this.myInfo()

      const us = new URL(window.location.href)
      this.minmaxIndex = us.searchParams.get('minmax') ? us.searchParams.get('minmax').toFloat() : 0

      this.adjustZoom()
      window.addEventListener('resize', this.adjustZoom);
      window.addEventListener('DOMContentLoaded', this.adjustZoom);

      this.initRoomState()

    }
  },
  mounted: function () {
    const self = this
    this.init()
    setTimeout(()=>{
      if (self.$refs['room-history-panel-component']) {
        self.count = self.$refs['room-history-panel-component'].getHistoryCount()
      }
    }, 500)

    const bg = localStorage.getItem('soundBG')
    if (bg === 'true') {
      this.sound.bg = true
    } else {
      if (!bg) {
        this.sound.bg = true
      } else {
        this.sound.bg = false
      }
    }

    const effect = localStorage.getItem('soundEffect')
    if (effect === 'true') {
      this.sound.effect = true
    } else {
      if (!effect) {
        this.sound.effect = true
      } else {
        this.sound.effect = false
      }
    }
  },
  created: function () {

  },
  unmounted: function () {

  }
}
</script>