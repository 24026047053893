<template>
  <div class="lbox2">
    <div :class="{lbox2_1:true}">
      <div>{{playerShortLabel}}</div>
      <div>{{result == null ? 0 : result.playerNumber}}</div>
      <div>{{result == null ? 0 : result.bankerNumber}}</div>
      <div>{{bankerShortLabel}}</div>
    </div>
    <div class="lbox2_2">
      <div class="lbox2_player lbox2_grids">
        <div class="lbox2_grid">
          <div v-if="room && cards.p1 !== ''">
            <img :src="cards.p1">
          </div>
        </div>
        <div class="lbox2_grid">
          <div v-if="room && cards.p2 !== ''">
            <img :src="cards.p2">
          </div>
        </div>
        <div class="lbox2_grid">
          <div v-if="room && cards.p1 !== ''">
            <img :src="cards.p1">
          </div>
        </div>
      </div>
      <div class="lbox2_banker lbox2_grids">
        <div class="lbox2_grid">
          <div v-if="cards.b1 !== ''">
            <img :src="cards.b1">
          </div>
        </div>
        <div class="lbox2_grid">
          <div v-if="cards.b2 !== ''">
            <img :src="cards.b2">
          </div>
        </div>
        <div class="lbox2_grid">
          <div v-if="cards.b3 !== ''">
            <img :src="cards.b3">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'room-wide-card-panel-component',
  props: {
    room: Object,
    isMultiRoom: Boolean
  },
  data () {
    return {
      cards: {
        p1: "", p2: "", p3: "", b1: "", b2: "", b3: ""
      },
      imageWin: '/resources/img/win/pwin.png',
      result: {
        result: {
          p: false,
          b: false,
          t: false,
          pp: false,
          bp: false
        },
        playerNumber: 0,
        bankerNumber: 0
      },
      showCount: 1,
      tmShowCount: null
    }
  },
  computed: {
    playerLabel: function () {
      return "Player";
    },
    bankerLabel: function () {
      return "Banker";
    },
    playerShortLabel: function () {
      return "P";
    },
    bankerShortLabel: function () {
      return "B";
    },
  },
  methods: {
    getCardImg: function (c) {
      if (!c) return ''
      return "/resources/img/card/" + c + ".png";
    },
    setRoundResult: function (result) {
      this.result = result
      this.cards.p1 = this.getCardImg(result.cards.player[0]);
      this.cards.p2 = this.getCardImg(result.cards.player[1]);
      this.cards.p3 = this.getCardImg(result.cards.player[2]);

      if(this.$API.util.isMobile()) {
        this.cards.b1 = this.getCardImg(result.cards.banker[2]);
        this.cards.b2 = this.getCardImg(result.cards.banker[0]);
        this.cards.b3 = this.getCardImg(result.cards.banker[1]);
      } else {
        this.cards.b1 = this.getCardImg(result.cards.banker[0]);
        this.cards.b2 = this.getCardImg(result.cards.banker[1]);
        this.cards.b3 = this.getCardImg(result.cards.banker[2]);
      }

      const obj1 = document.querySelector('.lbox2_player')
      const obj2 = document.querySelector('.lbox2_banker')
      obj1.classList.remove('win')
      obj2.classList.remove('win')

      if (result.result.p === true) {
        this.showResult('p')
      }
      if (result.result.t === true) {
        this.showResult('t')
      }
      if (result.result.b === true) {
        this.showResult('b')
      }
    },
    showResult (w) {
      const self = this
      const obj1 = document.querySelector('.lbox2_player')
      const obj2 = document.querySelector('.lbox2_banker')

      this.tmShowCount = setInterval(() => {
        if (self.showCount % 2 === 0) {
          obj1.classList.remove('win')
          obj2.classList.remove('win')
        } else {
          if (w === 'p') {
            if (obj1) {
              obj1.classList.add('win')
            }
          } else if (w === 't') {
            if (obj1) {
              obj1.classList.add('win')
            }

            if (obj2) {
              obj2.classList.add('win')
            }
          } else if (w === 'b') {
            if (obj2) {
              obj2.classList.add('win')
            }
          }
        }

        self.showCount++

        if (self.showCount === 6) clearInterval(self.tmShowCount)
      }, 500)
    }
  },
  mounted: function () {

  },
  unmounted: function () {

  }
}
</script>