<template>
  <room-wide-component />
</template>

<script>
import roomWideComponent from '@/components/room/wide-component.vue'

export default {
  name: "game-page",
  components: {
    roomWideComponent
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>